import * as ActionTypes from "./actionTypes";


export const saveGoogleLocation = (loc) => ({
    type: ActionTypes.SAVE_GOOGLE_LOC,
    payload: loc,
});

export const removeGoogleLocation = () => ({
    type: ActionTypes.REMOVE_GOOGLE_LOC,
});
