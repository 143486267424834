import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  verifyMsg: "",
  verifyErr: "",
  profile: "",
  totalData: "",
  savedProperty: [],
  agentEnquiries: [],
  enquiryDetail: "",
  allInspection: [],
  todayInspection: [],
  tomorrowInspection: [],
  weekInspection: [],
  monthInspection: [],
  reasonData: [],
  postDeactivate: null
};

export const Profiles = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_PROFILE:
      return {
        ...state,
        isloading: false,
        success: true,
        profile: action.payload,
      };

    case ActionTypes.FAIL_GET_USER_PROFILE:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };



    case ActionTypes.GET_DEACTIVATE_REASON:
      return {
        ...state,
        isloading: false,
        success: true,
        reasonData: action.payload,
      };

    case ActionTypes.FAIL_DEACTIVATE_REASON:
      return {
        ...state,
        isloading: false,
        success: false,
        reasonData: action.payload,
      };



    case ActionTypes.POST_DEACTIVATE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        postDeactivate: action.payload,
      };

    case ActionTypes.POST_DEACTIVATE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        postDeactivate: action.payload,
      };

    case ActionTypes.REMOVE_PROILES_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        verifyMsg: "",
        verifyErr: "",
        profile: "",
        totalData: "",
        reasonData: [],
        savedProperty: [],
        agentEnquiries: [],
        enquiryDetail: "",
        allInspection: [],
        todayInspection: [],
        tomorrowInspection: [],
        weekInspection: [],
        monthInspection: [],
        postDeactivate: null
      };

    default:
      return state;
  }
};
