import React, { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetDirectories,
} from "../../Redux/Directory/action";
import Loading from "../../Component/Loading/Loading";
import { fetchGetLeadForm } from "../../Redux/Enquiry/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Component";
import { categorizeData } from "../../utils/categorize";
import SearchField from "./SearchField";
import { removeDirectoryData } from "../../Redux/FindCompany/action";

const Directory = ({ setCatId, setToggle }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.directory.isloading, shallowEqual);
  const directories = useSelector((state) => state.directory.directory, shallowEqual);


  const handleClickNext = (cat_id) => {
    scrollToTop()
    setCatId(cat_id)

    if (cat_id) {
      setToggle({
        start: false,
        category: false,
        field: true,
      });

      dispatch(fetchGetLeadForm(cat_id));
      navigate("/")
    } else {
      toast.error("Please Select Category");
    }
  };



  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetDirectories());
    return () => { removeDirectoryData() }
  }, [dispatch]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }


  const categorizedData1 = categorizeData(directories)


  return (
    <div className="w-full h-full mt-10 pb-10">
      <h6 className="text-2xl font-medium w-3/4 mx-auto"> Browse Directory</h6>


      <SearchField handleClickNext={handleClickNext} />


      <div className="p-5 w-11/12 xl:w-3/4  mx-auto">

        <div
          className='space-y-6 p-4 min-h-[450px]'
          style={{
            WebkitColumns: '154px 3',
            MozColumns: '154px 3',
            columns: '154px 3',
            WebkitColumnGap: "0.75rem",
            MozColumnGap: "0.75rem",
            columnGap: "0.75rem",
          }}>


          {Object.entries(categorizedData1)?.map((subCat) => (
            <div
              key={subCat}
              className='h-fit w-full break-inside-avoid'
            >
              {/* {console.log(subCat[0])} */}

              <div>
                <div className='font-semibold text-lg'>{subCat[0]}</div>

                <div className='mt-2'>
                  {subCat[1]?.map((cat, index) => (
                    <div
                      key={index}
                    >
                      <Button
                        value={cat.cat_name}
                        className='text-start text-sm text-[#70747D] hover:underline'
                        handleClick={() => { handleClickNext(cat.cat_id) }}
                      />
                    </div>
                  ))}
                </div>
              </div>

            </div>
          ))}

        </div>

      </div>

    </div>
  );
};

export default Directory;
