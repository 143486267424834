import React from "react";
import { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { defImg } from "../../Asset/Assest";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
    fetchGetChatList,
    fetchGetOfferListInPost,
    fetchSendFile,
    fetchSendMessage,
    postHireAgent,
    removeFileData,
    removeInboxData,
} from "../../Redux/Inbox/action";
import Loading from "../../Component/Loading/Loading";
import { AiOutlineSend } from "react-icons/ai";
import { toast } from "react-toastify";
import { BsCheck, BsCheck2All } from "react-icons/bs";
import ScrollToBottom from "react-scroll-to-bottom";
import { RiAttachment2 } from "react-icons/ri";
import { Button, Overlay, Modal } from "../../Component";
import CompanyDetails from "./CompanyDetails";
// import Overlay from "./Overlay";
// import Modal from "./Modal";
import { fetchUploadImages } from "../../Redux/Images/action";

const MyInbox = () => {
    const [userName, setUserName] = useState("");
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [image, setImage] = useState("");
    const [toggleModal, setToggleModal] = useState(false)
    const dispatch = useDispatch();
    const token = Cookies.get("token");
    const navigate = useNavigate();

    const [searchParams] = useSearchParams()

    //pulling data from url
    const id = searchParams.get('id')
    const isStatus = searchParams.get('isStatus')
    const hirID = searchParams.get('hirID')

    useEffect(() => {
        dispatch(fetchGetOfferListInPost(id, token))
    }, [id, token, dispatch, isStatus])


    // useEffect(() => {
    //     if (isStatus === "Hired") {
    //         setIUserId(hirID)
    //         dispatch(fetchGetChatList(id, hirID, token));
    //     }
    // }, [dispatch, isStatus, id, token, hirID])




    const userId = useSelector((state) => state.profiles.profile.userid)
    const loading = useSelector((state) => state.inbox.isloading, shallowEqual);
    const hireSuccess = useSelector((state) => state.inbox.hire, shallowEqual);
    const companyAndChats = useSelector((state) => state.inbox.replies, shallowEqual);

    const chatData = useSelector((state) => state.inbox.chatList, shallowEqual);
    // in closed enquiries, company and the chat data is empty.

    const errMessage = useSelector((state) => state.inbox.errMsg, shallowEqual);
    // console.log(companyAndChats, "hire")
    // call it's api after selecting a company

    // const file = useSelector((state) => state.image.images, shallowEqual);
    const file = useSelector((state) => state.inbox.file, shallowEqual);

    const companyData = companyAndChats?.enquiry_users?.filter((val) => {
        // Check if 'hired_at' is null for everyone
        const allHiredAtNull = companyAndChats?.enquiry_users?.every(user => user.hired_at === null);

        // If 'hired_at' is null for everyone, don't filter
        if (allHiredAtNull) {
            return true;
        } else {
            // If 'hired_at' exists for at least one user, filter based on that
            return val.hired_at;
        }
    });



    // console.log("image File", file)

    // id for posting chat messages
    // const userEnqId = companyAndChats?.user_enquiryid
    //Right side display data
    const chatList = chatData?.chats
    // console.log(chatList)


    const [iUserId, setIUserId] = useState(null)

    const handleNavigateBack = () => {
        navigate(-1);
    };

    const [companyName, setCompanyName] = useState("")
    const [companyImg, setCompanyImg] = useState("")
    const [selectedCompanyInfo, setSelectedCompanyInfo] = useState({})

    // console.log("first", selectedCompanyInfo)
    const handleClickChat = (userId, userName, companyName, companyImg, companyAllData) => {
        dispatch(fetchGetChatList(id, userId, token));
        setIUserId(userId)
        setUserName(userName);
        setCompanyName(companyName)
        setCompanyImg(companyImg)
        setSelectedCompanyInfo(companyAllData)
    };

    useEffect(() => {
        return () => {
            dispatch(removeInboxData());
        }
    }, [dispatch])


    const body = {
        "user_enquiry_id": chatData?.user_enquiryid,
        "txt_reply": message,
        "chatfile": file?.src ?? null
    };
    console.log(image?.name)

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleClickSend()
        }
    };

    const handleClickSend = () => {
        dispatch(fetchSendMessage(body, token));
        setTimeout(() => {
            dispatch(removeFileData());
            dispatch(fetchGetOfferListInPost(id, token))
            dispatch(fetchGetChatList(id, iUserId, token));
            setMessage("");
        }, 200);
    };


    useEffect(() => {
        file && setMessage(image?.name);
    }, [file, image?.name]);


    useEffect(() => {
        scrollToTop();
    }, []);


    useEffect(() => {
        const sortedDateTimes = chatList?.sort(
            (a, b) => new Date(a.added_at) - new Date(b.added_at)
        ).reverse();
        setMessages(sortedDateTimes);
    }, [chatList]);

    useEffect(() => {
        errMessage && toast.error(errMessage);
        errMessage && dispatch(removeInboxData());
        setTimeout(() => {
            errMessage && dispatch(fetchGetOfferListInPost(id, token));
        }, 1000);
    }, [errMessage]);


    useEffect(() => {
        hireSuccess && toast.success(hireSuccess)
    }, [hireSuccess])


    function containsImageExtension(text) {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)/i;
        return imageExtensions.test(text);
    }

    console.log(selectedCompanyInfo)
    const handleClickHire = () => {
        toast.info("Sending request... Please wait.")
        dispatch(postHireAgent(selectedCompanyInfo?.i_userid, token))
        dispatch(removeInboxData());

        setTimeout(() => {
            dispatch(fetchGetOfferListInPost(id, token));
        }, 1000);
    }


    if (!token) {
        return <Navigate to={`/auth/sign-in`} replace />;
    }

    // if (loading) {
    //     return (
    //         <div className="w-full h-screen">
    //             <Loading />
    //         </div>
    //     );
    // }

    // console.log(companyData)
    // console.log(messages)

    return (
        <>
            <div className="py-16 md:py-24 h-full w-full bg-gray-50">

                <div className="w-11/12 xl:w-3/4 mx-auto mt-5 2xl:mt-10">
                    <div
                        className="text-sm md:text-base flex items-center gap-x-2 text-textPrimary cursor-pointer w-fit"
                        onClick={handleNavigateBack}
                    >
                        <BsArrowLeft />
                        <p>Go Back</p>
                    </div>


                    <div className="w-full grid grid-cols-12 h-[35rem] mt-5 2xl:mt-10 bg-white">

                        <div className="w-full col-span-4 h-full border overflow-y-auto rounded-l-xl pb-2">
                            <h5 className="font-medium text-xl p-5">Messages</h5>
                            <hr className="w-full" />
                            {/* {console.log(companyData, "cmp Data")} */}
                            {companyData?.length ?
                                companyData?.map((val, index) => {
                                    return (
                                        <div
                                            key={index} // val.id is null in api response
                                            className="px-3 hover:bg-gray-50 cursor-pointer w-full overflow-hidden"
                                            onClick={() =>
                                                handleClickChat(val.i_userid, val.name, val.company_name, val.image, val)
                                            }// this selects the company to initiate chat
                                        >
                                            <div className="flex w-full gap-x-5 py-5">
                                                <div>

                                                    <div className="w-10 aspect-square rounded-full lg:w-14 ">
                                                        <img
                                                            src={val?.image}
                                                            onError={(e) => e.target.src = defImg}
                                                            alt=""
                                                            className="w-full h-full rounded-full object-fill"
                                                        />
                                                    </div>
                                                </div>


                                                <div className="w-11/12 overflow-hidden">
                                                    <div className="w-full flex items-center justify-between">
                                                        <h6 className="font-medium text-base truncate w-3/4">
                                                            {val.company_name}
                                                        </h6>
                                                        {val.unseen_msg ? (
                                                            <div className="w-7 h-7 flex justify-center items-center text-center border rounded-full bg-primary text-white text-xs">
                                                                <p>{val.unseen_msg}</p>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <p className="text-textSecondary truncate text-sm mt-1">
                                                        {val.latest_msg?.msg}
                                                    </p>
                                                </div>
                                            </div>
                                            <hr className="w-full text-textSecondary" />
                                        </div>
                                    );
                                })
                                : null
                            }
                        </div>
                        <div
                            className={`${messages ? "block" : "hidden"
                                } w-full col-start-5 col-end-13 bg-pink-50 h-full border overflow-y-auto rounded-r-xl`}
                        >
                            <div className="bg-white p-5 flex justify-between items-center gap-3">

                                <div
                                    onClick={() => setToggleModal(true)}
                                    className="flex justify-center items-center gap-3 cursor-pointer"
                                >
                                    <div>
                                        <div className="w-10 aspect-square rounded-full lg:w-14 ">
                                            <img
                                                src={companyImg}
                                                onError={(e) => e.target.src = defImg}
                                                alt=""
                                                className="w-full h-full rounded-full object-cover"
                                            />
                                        </div>
                                    </div>

                                    <h5 className="font-medium text-xl">{companyName}</h5>
                                </div>
                                {isStatus !== "Closed" &&
                                    <Button
                                        disable={isStatus === "Hired" ? true : hireSuccess ? true : false}
                                        value={isStatus === "Hired" ? "Hired" : hireSuccess ? "Hired" : "Hire"}
                                        className="bg-primary text-white rounded-xl border py-2 px-5 disabled:bg-slate-200 disabled:text-black"
                                        handleClick={() => handleClickHire()}
                                    />
                                }
                            </div>
                            <hr className="w-full" />

                            <div className="w-full h-[65%] overflow-y-auto">
                                <ScrollToBottom className="h-full w-full overflow-y-scroll px-5">
                                    {messages && messages.length
                                        ? messages.map((val) => {
                                            return (
                                                <div
                                                    key={val.id}
                                                    className={`${userName !== val.first_name + " " + val.last_name
                                                        ? ""
                                                        : "flex-row-reverse"
                                                        } flex w-full p-2 gap-x-5`}
                                                >

                                                    <div>
                                                        <div className="w-[2.6rem] aspect-square rounded-full overflow-hidden mt-2">
                                                            <img
                                                                src={
                                                                    val.thumbnail
                                                                        ? val.thumbnail
                                                                        : defImg
                                                                }
                                                                onError={(e) => e.target.src = defImg}
                                                                alt=""
                                                                className="w-full h-full object-cover"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={` ${userName !== val.first_name + " " + val.last_name
                                                            ? "text-left text-textSecondary bg-white w-fit rounded-r-xl rounded-bl-xl"
                                                            : "text-right text-textSecondary bg-white w-fit rounded-l-xl rounded-br-xl"
                                                            } py-2 px-4 mt-2`}
                                                    >
                                                        {val?.file_url ? (
                                                            <>
                                                                {containsImageExtension(val?.file_url) && (
                                                                    <>
                                                                        <a download={val?.msg} target="_blank" rel="noreferrer" href={val?.file_url}>
                                                                            <img
                                                                                src={val.file_url}
                                                                                alt="val.comment"
                                                                                className="w-40 h-32 object-contain"
                                                                                onError={(e) => e.target.src = defImg}
                                                                            />
                                                                        </a>
                                                                    </>
                                                                )}

                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className={`text-sm ${val.file_url ? "text-blue-500" : ""}`}
                                                                    href={val.file_url}
                                                                    download={val.msg}
                                                                >
                                                                    {val.msg}
                                                                </a>

                                                            </>

                                                        ) : (
                                                            <p
                                                                className={`text-sm ${val.file_url ? "text-blue-500" : ""}`}
                                                            >
                                                                {val.msg}
                                                            </p>
                                                        )}
                                                        {/* { containsImageExtension(val?.file_url) ? (
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className={`text-sm ${val.file_url ? "text-blue-500" : ""
                                                                    }`}
                                                                href={val.file_url}
                                                                download={val.msg}
                                                            >
                                                                {val.msg}
                                                            </a>
                                                        ) : val.file_url ? (
                                                            <>
                                                                <a download={val?.msg} target="_blank" rel="noreferrer" href={val?.file_url}>
                                                                    <img
                                                                        src={val.file_url}
                                                                        alt="val.comment"
                                                                        className="w-40 h-32 object-contain"
                                                                        onError={(e) => e.target.src = defImg}
                                                                    />
                                                                </a>
                                                            </>
                                                        ) : (
                                                            <p
                                                                className={`text-sm ${val.file_url ? "text-blue-500" : ""
                                                                    }`}
                                                            >
                                                                {val.msg}
                                                            </p>
                                                        )} */}
                                                        <div className="flex items-start mt-2 gap-x-2">
                                                            <p className="text-[0.6rem]  text-textSecondary">
                                                                {val.added_at}
                                                            </p>
                                                            {val.user_id !== userId ? (
                                                                val.seen_at ? (
                                                                    <div>
                                                                        <BsCheck2All className="text-xs text-primary" />
                                                                        {/* <p className="text-[0.6rem]  text-textSecondary"> {val.seen_at}</p> */}
                                                                    </div>
                                                                ) : (
                                                                    <BsCheck className="text-sm text-textSecondary" />
                                                                )
                                                            ) : null}
                                                            {/* {val.from_me === 1 ? (
                                                            val.is_seen === "1" ? (
                                                                <BsCheck2All className="text-xs text-primary" />
                                                            ) : (
                                                                <BsCheck className="text-sm text-textSecondary" />
                                                            )
                                                        ) : null} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : null}
                                </ScrollToBottom>
                            </div>
                            {isStatus !== "Closed" ?
                                <div className="w-full h-[17.5%] gap-x-5 bg-white flex justify-between items-center px-5">

                                    <div
                                        className={`border rounded-full w-11/12 outline-none text-sm text-textSecondary flex items-center 
                                ${message === image?.name ? "pl-5" : "px-5"}`}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Write message in here..."
                                            onKeyDown={handleKeyPress}
                                            onChange={(e) => setMessage(e.target.value)}
                                            value={message}
                                            disabled={message === image?.name ? true : false}
                                            className="outline-none py-2 md:py-3 w-full"
                                        />
                                        {message === image?.name ? (
                                            <span
                                                className="bg-red-700 w-14 cursor-pointer py-2 text-center md:py-3 text-white rounded-r-full"
                                                onClick={() => {
                                                    setMessage("");
                                                    setImage("");
                                                    dispatch(removeInboxData());
                                                    setTimeout(() => {
                                                        dispatch(fetchGetOfferListInPost(id, token));
                                                    }, 100);
                                                }}
                                            >
                                                X
                                            </span>
                                        ) : null}
                                    </div>


                                    {/* UPLOAD IMG */}
                                    <label className="w-fit p-3 bg-primary text-white rounded-full cursor-pointer">
                                        <RiAttachment2 className="text-white text-xl" />
                                        <input
                                            type="file"
                                            className="w-full h-full border hidden"
                                            // accept=".doc, .docx, .pdf"
                                            // accept="image/jpg, image/png, image/webp, image/jpeg"
                                            onChange={(e) => {
                                                console.log(e.target.files[0]?.name)
                                                setImage(e.target.files[0]);
                                                const formData = new FormData();
                                                formData.append("files", e.target?.files?.[0]);
                                                // dispatch(fetchUploadImages(formData, token));
                                                dispatch(fetchSendFile(formData, token));
                                            }}
                                        />
                                    </label>
                                    <div
                                        className="p-3 cursor-pointer bg-primary text-white rounded-full"
                                        onClick={handleClickSend}
                                    >
                                        <AiOutlineSend className="text-xl" />
                                    </div>
                                </div>
                                :
                                <div className="w-full h-[17.5%] gap-x-5 bg-white flex justify-between items-center px-5">
                                    <p className="text-rose-500 text-sm text-center">Case Closed: You can no longer chat with this company/person as the case is closed or the job is done. You can still view the old chat history.</p>
                                </div>
                            }

                        </div>
                    </div>
                </div >
            </div >

            <div>
                {toggleModal &&
                    <Modal
                        title={"Company Details"}
                        contents={<CompanyDetails compData={selectedCompanyInfo} />}
                        closeModal={() => setToggleModal(false)}
                    />
                }

                {toggleModal &&
                    <Overlay onClick={() => setToggleModal(false)} />
                }
            </div>
        </>
    );
};

export default MyInbox;
