import { geocodeByAddress } from "react-google-places-autocomplete";

export const getLocationData = async (location) => {
    if (location) {
        const search = location.label;
        const promises = await geocodeByAddress(search);

        const geocodes = await Promise.all(promises);
        const description = geocodes?.[0];

        // property state
        const state = description?.address_components.filter((k) =>
            k.types.includes("administrative_area_level_1")
        )?.[0].short_name;

        //postcode
        const postcode = description?.address_components.filter((k) =>
            k.types.includes("postal_code")
        )?.[0].short_name;

        // property Suburb
        const suburb = description?.address_components.filter((k) =>
            k.types.includes(
                "locality" || "political" || "administrative_area_level_2"
            )
        )?.[0].short_name;

        return ({ state, postcode, suburb })
    } else return
};