import React, { useEffect } from 'react'
import { Button, ItemsCarousel, Download, NewsCard } from '../../../Component'
import { enquiryImg, classiBazarLogo, homeBg } from '../../../Asset/Assest'
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from '../../../Component/NavigateTop';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAllNewsData, removeNewsData } from '../../../Redux/News/action';


const Article = () => {
    const dispatch = useDispatch();

    const blogs = useSelector((state) => state.news.news, shallowEqual);

    useEffect(() => {
        scrollToTop();
        dispatch(fetchGetAllNewsData());
        return () => {
            dispatch(removeNewsData());
        };
    }, [dispatch]);

    const displayArr = blogs?.blogs?.slice(0, 6);

    // const displayArr = [
    //     {
    //         id: 1,
    //         image: enquiryImg,
    //         title: "ClassiLead",
    //         desc: "This article is about description and cats and dogs and cats and dogs"
    //     },
    //     {
    //         id: 2,
    //         image: classiBazarLogo,
    //         title: "ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead",
    //         desc: "This article "
    //     },
    //     {
    //         id: 3,
    //         image: enquiryImg,
    //         title: "ClassiLead ",
    //         desc: "This article "
    //     },
    //     {
    //         id: 4,
    //         image: homeBg,
    //         title: "ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead",
    //         desc: "This article is about description and cats and dogs and cats and dogs"
    //     },
    //     {
    //         id: 5,
    //         image: enquiryImg,
    //         title: "ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead",
    //         desc: "This article is about description and cats and dogs and cats and dogs"
    //     }
    // ]

    const navigate = useNavigate()

    const executeFunctions = (id) => {
        scrollToTop();
        navigate(`/blog/${id}`);
    };


    return (
        <div>

            <section className='space-y-8'>

                <div>
                    <div className='text-xl md:text-3xl font-semibold'>Latest Articles</div>
                    <hr className='bg-primary h-1 w-20' />
                </div>


                {console.log(displayArr)}
                <div>
                    <ItemsCarousel
                        className={"px-3 py-6"}
                        slidesToShow={displayArr?.length > 4 ? 4 : displayArr?.length}
                        contents={displayArr?.map((val) => (
                            <NewsCard
                                key={val.id}
                                id={val.id}
                                image={val.picture}
                                date={val.added_date}
                                title={val.title}
                                desc={val.content}
                                executeFunctions={executeFunctions}
                            // image={val.image}
                            // desc={val.desc}
                            />
                        ))}
                    />
                </div>

                <Button
                    value={
                        <>
                            <div className='text-base md:text-xl'>Explore more articles about</div>
                            <GoArrowRight className='text-2xl' />
                        </>
                    }
                    handleClick={() => {
                        navigate("/blog")
                        scrollToTop();
                    }}
                    className="flex justify-center items-center gap-4 px-7 py-3 rounded-xl bg-white duration-200 border hover:bg-gray-100 text-black"
                />

            </section>


            <section className="mx-auto">
                <Download />
            </section>


        </div>
    )
}

export default Article



