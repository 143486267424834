import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer, Header } from "./Component";
import {
  ChangePassword,
  Directory,
  ForgotPassword,
  LeadEnquiry,
  MyEnquiries,
  PrivacyPolicy,
  Profile,
  Signin,
  Signup,
  TermsofUse,
  VerifyAccount,
  Chat,
  Blog,
  BlogDetails,
  ContactUs,
  AdvertiseWithUs,
  FindCompany,
  CompanyDetailPage


} from "./Pages";
import AuthRoute from "./Layout/AuthRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountRoute from "./Layout/Account";
import { fetchGetUserProfile } from "./Redux/UserProfile/action";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const App = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    token && dispatch(fetchGetUserProfile(token));
  }, [token, dispatch]);


  // Had to commit this crime cuz the footer now needs a category link as well and making it's state global requires changes in multiple pages.
  const [toggle, setToggle] = useState({
    start: false,
    category: true,
    field: false,
  });
  const [catId, setCatId] = useState("");


  return (
    <div className="bg-white box-border min-h-screen relative">
      <BrowserRouter>
        <Header />
        <ToastContainer />
        <Routes>
          <Route path="/auth" element={<AuthRoute />}>
            <Route path="sign-in" element={<Signin />} />
            <Route path="sign-up" element={<Signup />} />
            <Route path="verify" element={<VerifyAccount />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>

          <Route path="/" element={
            <LeadEnquiry
              toggle={toggle}
              setToggle={setToggle}
              catId={catId}
              setCatId={setCatId}
            />
          } />
          <Route path="/listing" element={<Directory setToggle={setToggle} setCatId={setCatId} />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsofUse />} />

          <Route path="/blog">
            <Route index element={<Blog />} />
            <Route path=":id" element={<BlogDetails />} />
          </Route>

          <Route path="/chat" element={<Chat />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/advertise-with-us" element={<AdvertiseWithUs />} />

          <Route path="/find-company">
            <Route index element={<FindCompany setToggle={setToggle} setCatId={setCatId} />} />
            <Route path=":id" element={<CompanyDetailPage />} />
          </Route>

          {/* profile routes */}
          <Route path="/me" element={<AccountRoute Component={Profile} />}>
            <Route path="enquiries" element={<MyEnquiries />} />
          </Route>
        </Routes>
        <Footer
          setToggle={setToggle}
          setCatId={setCatId}
        />
      </BrowserRouter>
    </div>
  );
};

export default App;
