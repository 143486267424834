import * as ActionTypes from "./actionTypes";

const initialState = {
    isloading: true,
    success: false,
    company: null,
    review: null
};

export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_REVIEW_SUCCESS:
            return {
                ...state,
                isloading: false,
                success: true,
                review: action.payload.status,
            };

        case ActionTypes.GET_REVIEW_FAIL:
            return {
                ...state,
                isloading: false,
                success: false,
                review: action.payload,
            };

        case ActionTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                isloading: false,
                success: true,
                company: action.payload,
            };

        case ActionTypes.GET_PROFILE_FAIL:
            return {
                ...state,
                isloading: false,
                success: false,
                company: action.payload,
            };

        case ActionTypes.REMOVE_COMPANY_DATA:
            return {
                ...state,
                isloading: true,
                success: false,
                company: null,
                review: null
            };

        default:
            return state;
    }
};
