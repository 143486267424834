import React, { useEffect } from 'react'
import { Button, Modal, Overlay, RadioButton } from '../../../Component'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDeactivateReasons, postDeactivateAcc, removeProfilesData } from '../../../Redux/UserProfile/action'
import Loading from '../../../Component/Loading/Loading'
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'


const Deactivate = () => {

    const [toggleModal, setToggleModal] = useState(false)

    return (
        <>
            <div className="flex justify-end items-center">
                <Button
                    value="Delete Account"
                    handleClick={() => setToggleModal(true)}
                    className="py-2 rounded-xl px-5 transition ease-in-out duration-300 bg-red-600 hover:bg-red-700 hover:shadow-[2px_2px_5px_rgba(0,0,0,0.3)] text-white"
                />
            </div>

            <div>
                {toggleModal &&
                    <Modal
                        title={"Delete Account"}
                        contents={<DeactivateContent />}
                        closeModal={() => setToggleModal(false)}
                    />
                }

                {toggleModal &&
                    <Overlay onClick={() => setToggleModal(false)} />
                }
            </div>
        </>
    )
}

export default Deactivate

function DeactivateContent() {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const token = Cookies.get("token");

    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(getDeactivateReasons());
    }, [dispatch]);

    const radioOptions = useSelector(state => state.profiles.reasonData);
    const isLoading = useSelector(state => state.profiles.isLoading);
    const postDeactivate = useSelector((state) => state.profiles.postDeactivate);
    const success = useSelector((state) => state.profiles.success);


    useEffect(() => {
        if (success && postDeactivate?.code === 200) {
            navigate("/")
            toast.success(postDeactivate.message)
            Cookies.remove("token");
            dispatch(removeProfilesData())
            window.location.reload(false);
        }
    }, [dispatch, navigate, postDeactivate, success])

    const [radioSelectedOption, setRadioSelectedOption] = useState({
        reason: radioOptions[0]?.reason_id,
        description: radioOptions[0]?.name
    });

    const handleRadioChange = (option) => {
        setRadioSelectedOption(option);
    };

    const handleDelete = () => {
        dispatch(postDeactivateAcc(radioSelectedOption, token))
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {page === 1 &&
                <div className='space-y-4'>
                    <div className='space-y-3'>
                        {radioOptions?.map((option) => (
                            <RadioButton
                                key={option.reason_id}
                                id={option.reason_id}
                                name="options"
                                value={{
                                    reason: option.reason_id,
                                    description: option.name
                                }}
                                label={option.name}
                                checked={radioSelectedOption.reason === option.reason_id}
                                onChange={() => handleRadioChange({
                                    reason: option.reason_id,
                                    description: option.name
                                })}
                            />
                        ))}
                    </div>
                    <div className='flex justify-end items-center'>
                        <button
                            disabled={!radioSelectedOption.reason}
                            onClick={() => setPage(2)}
                            className='text-white bg-red-600 rounded-md px-3 py-1 disabled:bg-gray-200 disabled:text-black'
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            }
            {page === 2 &&
                <div className='space-y-4'>

                    <p>
                        This action is irreversible! Are you sure you want to delete your account? All your data will be permanently deleted and cannot be undone.
                    </p>


                    <div className='flex justify-end items-center gap-6'>
                        <button
                            onClick={() => handleDelete()}
                            className='text-white bg-red-600 rounded-md px-3 py-1'
                        >
                            Delete
                        </button>
                        <button
                            onClick={() => setPage(1)}
                            className=' bg-gray-200 border border-gray-200 text-black rounded-md px-3 py-1'
                        >
                            Back
                        </button>
                    </div>
                </div>
            }
        </>
    );
}
