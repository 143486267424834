import * as ActionTypes from "./actionTypes";

const initialState = {
    isloading: true,
    success: false,
    companyList: [],
};

export const FindCompany = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_FIND_COMPANY_LIST:
            return {
                ...state,
                isloading: false,
                success: true,
                companyList: action.payload,
            };

        case ActionTypes.REMOVE_FIND_COMPANY:
            return {
                isloading: true,
                success: false,
                companyList: [],
            };

        default:
            return state;
    }
};
