import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const directoryLoading = () => ({
    type: ActionTypes.FIND_COMPANY_LOADING,
});

export const removeDirectoryData = () => ({
    type: ActionTypes.REMOVE_FIND_COMPANY,
});

export const fetchFindCompanyList = (id, location, page = 1) => async (dispatch) => {
    await axios
        .get(`${BaseUrl}/search_lead_agencies?category=${id}&address=${location}&page=${page}`)
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.GET_FIND_COMPANY_LIST,
                payload: data,
            });
        });
};
