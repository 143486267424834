import React, { useEffect, useState } from "react";
import { Button, FormikControl } from "../../../Component";
import { Form, Formik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchChangePassword,
  removeProfileData,
} from "../../../Redux/Profile/action";
import { toast } from "react-toastify";
import { fetchGetUserProfile } from "../../../Redux/UserProfile/action";

const UpdatePassword = ({ setPasswordPopup }) => {
  const [showCurrent, setSetCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const message = useSelector((state) => state.profile.message, shallowEqual);
  const errMessage = useSelector((state) => state.profile.errMsg, shallowEqual);

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const initialValues = {
    current_password: "",
    password: "",
    password_confirm: "",
  };

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchChangePassword(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 5000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeProfileData());
    message && dispatch(fetchGetUserProfile(token));
    message && setPasswordPopup(false);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeProfileData());
    errMessage && dispatch(fetchGetUserProfile(token));
    errMessage && setPasswordPopup(false);
  }, [errMessage]);

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 lg:w-3/4 left-[5%] lg:left-[10%] pt-5 md:pt-10 pb-16 ">
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <h4 className="font-bold text-xl md:text-3xl text-primary text-center mb-8">
          Change Password
        </h4>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full mt-7">
                <FormikControl
                  label="Old Password"
                  control="password"
                  name="current_password"
                  placeholder="Enter Your Old Password"
                  type={`${showCurrent ? "text" : "password"}`}
                  setShowPassword={setSetCurrent}
                  showPassword={showCurrent}
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="New Password"
                  control="password"
                  name="password"
                  placeholder="Enter Your New Password"
                  type={`${showNew ? "text" : "password"}`}
                  setShowPassword={setShowNew}
                  showPassword={showNew}
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Confirm Password"
                  control="password"
                  name="password_confirm"
                  placeholder="Confirm Your Password"
                  type={`${showConfirm ? "text" : "password"}`}
                  setShowPassword={setShowConfirm}
                  showPassword={showConfirm}
                  autoComplete="off"
                />
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-primary cursor-pointer font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setPasswordPopup(false)}
                >
                  <Button value="Cancel" />
                </div>

                <div className="border w-full rounded-2xl bg-primary cursor-pointer text-white text-sm md:text-base font-medium py-4 text-center">
                  <Button value="Save Changes" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdatePassword;
