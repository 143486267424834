import axios from "axios";
import { BaseUrl, BaseUrl2 } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
    type: ActionTypes.COMPANY_LOADING,
});

export const removeCompanyData = () => ({
    type: ActionTypes.REMOVE_COMPANY_DATA,
});


export const postCompanyReview = (body, token) => async (dispatch) => {
    await axios
        .post(`${BaseUrl}/lead/reviews/14`, body, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.GET_REVIEW_SUCCESS,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: ActionTypes.GET_REVIEW_FAIL,
                payload: e?.response?.data,
            });
        });
};


export const getCompanyProfile = (id, token) => async (dispatch) => {
    await axios
        .get(`${BaseUrl}/profile/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.GET_PROFILE_SUCCESS,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: ActionTypes.GET_PROFILE_FAIL,
                payload: e?.response?.data,
            });
        });
};
