export function categorizeData(data) {
    const categorizedData = {};

    // Sort the data alphabetically by name
    const sortedData = [...data].sort((a, b) => a.cat_name.localeCompare(b.cat_name));

    // Iterate over each item and categorize them by their initial alphabet
    sortedData.forEach(item => {
        const initial = item.cat_name.charAt(0).toUpperCase(); // Get the initial alphabet and convert to uppercase
        if (!categorizedData[initial]) {
            categorizedData[initial] = [];
        }
        categorizedData[initial].push(item);
    });

    return categorizedData;
}