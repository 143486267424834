import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { IoStar } from "react-icons/io5";
import { scrollToTop } from '../NavigateTop';
import { defImg, homeBg } from '../../Asset/Assest';
import { getStarsFromRating } from '../../utils/getStarsFromRating';
import { IoIosStar } from 'react-icons/io';


const ListingList = ({
    userId,
    companyName,
    streetAddress,
    state,
    suburb,
    bio,
    image,
    rating,
    handleGetQuoteForm
}) => {

    const navigate = useNavigate()

    const handleCompanyDetail = () => {
        navigate(`/find-company/${userId}`)
    }

    return (

        <div className={`group py-2 duration-300 gap-4 flex rounded-md bg-white shadow-[1px_2px_5px_rgba(0,0,0,0.1)] hover:shadow-[1px_2px_5px_rgba(0,0,0,0.3)]`}>

            <div className='flex-shrink-0 w-1/3 md:w-[18%] lg:w-[20%] 2xl:w-[16%]'>
                <div className='w-full h-full'>
                    <img
                        className='p-2 duration-500 ease-in-out object-contain object-center w-full h-full'
                        src={image ?? homeBg}
                        onError={(e) => e.target.src = homeBg}
                        alt={'items'}
                    />
                </div>
            </div>

            <div className='w-full flex flex-col gap-4 py-2 md:flex-row justify-center items-start'>

                <div className='flex-grow w-full px-2 space-y-1 lg:space-y-2'>
                    <div
                        onClick={handleCompanyDetail}
                        className='group-hover:underline cursor-pointer line-clamp-2 text-left text-xl text-primary font-semibold'
                    >
                        {companyName}
                    </div>

                    <div className='flex items-center justify-start flex-wrap gap-x-0.5 text-xl'>
                        {getStarsFromRating(Math.min(rating), <IoIosStar className='text-[#D4CDC5]' />)}
                    </div>

                    <div className='text-black font-medium line-clamp-2 text-left text-sm'>
                        {(suburb || state || streetAddress) && `${suburb} ${state} ${streetAddress} Sydney AU 7740`}
                    </div>

                    <div className='text-[#70747D] line-clamp-3 text-left text-sm'>
                        {bio}
                    </div>

                </div>

                <div className='pr-8 h-full w-full md:w-fit flex justify-center items-center'>
                    <button
                        onClick={() => {
                            handleGetQuoteForm();
                            scrollToTop();
                        }}
                        className='ml-auto whitespace-nowrap bg-primary text-white text-sm md:text-base px-4 py-2 lg:px-8 lg:py-3 rounded-md'
                    >Get a Quote
                    </button>
                </div>
            </div>

        </div>
    )
}

export default ListingList