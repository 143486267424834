import React, { useEffect, useState } from 'react'
import { Button, Modal, Overlay } from '../../Component'
import { useNavigate } from 'react-router-dom'
import { IoMdClose } from "react-icons/io";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetMyEnquiryDetail, removeEnquiryData } from '../../Redux/Enquiry/action';
import { formatDate } from "../../utils/formatDate";


/** Disable navigation to chat if there's no applied companies*/
/** New logics: when enquiry is closed, disable chats */
const ListItems = ({
    id,
    name,
    address,
    date,
    // status,
    metaDesc,
    leadData,
    agents,
    isStatus,

    // If you are a new dev and you have been assigned to this project, then GOD BLESS YOU (RUN as far as possible) and goodluck. I am not paid enough to clean this B.S
    allData,
}) => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [viewDetails, setViewDetails] = useState({})
    const [viewMetaDesc, setViewMetaDesc] = useState(false)

    const [isloading, setisloading] = useState(false)
    const enquiryDetailsData = useSelector((state) => state.enquiry.enquiryDetail, shallowEqual);

    useEffect(() => {
        setisloading(false)
    }, [enquiryDetailsData])


    useEffect(() => {
        return () => {
            removeEnquiryData()
        }
    }, [])

    const [enquiryDetails, setEnquiryDetails] = useState(null)
    const [detailId, setDetailId] = useState(null)

    const handleViewDetails = (id) => {
        setisloading(true)
        setDetailId(id)

        if (!viewDetails?.[id]) {
            dispatch(fetchGetMyEnquiryDetail(id))
        }
        setViewDetails({
            ...viewDetails,
            [id]: !viewDetails[id],
        });
    };


    useEffect(() => {
        if (enquiryDetailsData && detailId) {
            setEnquiryDetails((prevDetailsStorage) => ({
                ...prevDetailsStorage,
                [detailId]: enquiryDetailsData?.id === detailId ? enquiryDetailsData : prevDetailsStorage?.[detailId],
            }));
        }
    }, [enquiryDetailsData, detailId]);


    const handleChat = () => {
        const hireId = enquiryDetailsData?.hired_user?.chat_user_id
        const queryParams = new URLSearchParams();
        queryParams.set('id', id);
        queryParams.set('isStatus', isStatus);

        if (isStatus === "Hired") {
            queryParams.set('hirID', hireId);
        }
        navigate(`/chat?${queryParams}`)
    }


    const totalCompany = allData?.agents?.filter((val) => val.status !== "Declined").length ?? 0
    // const totalCompany = enquiryDetails?.[id]?.agents?.filter((val) => val.status !== "Declined").length ?? 0
    return (
        <>
            <div className='space-y-8'>
                <div className='divide-y bg-white rounded-lg border flex flex-col justify-center items-start'>

                    <div className='p-6 text-xl font-semibold'>Enquiries</div>


                    <div className='w-full p-6 gap-6 flex flex-wrap flex-col justify-between'>

                        <div className='flex justify-between flex-wrap items-start gap-6'>
                            <div className='space-y-2'>
                                <div className='text-sm text-gray-500'>Name</div>
                                <div className='text-sm'>{name}</div>
                            </div>

                            <div className='space-y-2'>
                                <div className='text-sm text-gray-500'>Location</div>
                                <div className='text-sm'>{address}</div>
                            </div>

                            <div className='space-y-2'>
                                <div className='text-sm text-gray-500'>Enquiry Date</div>
                                <div className='text-sm'>{date}</div>
                            </div>


                        </div>

                        <div className='flex justify-between items-start lg:items-center gap-3'>
                            <div className='w-full flex-wrap lg:flex-nowrap flex justify-start items-center gap-3 lg:gap-6'>

                                {isStatus === "Pending" &&
                                    <div className='text-sm py-3 whitespace-nowrap space-y-2'>
                                        <div>Applied Companies: {totalCompany}</div>
                                    </div>
                                }

                                {totalCompany ?
                                    < Button
                                        value={"View Companies"}
                                        handleClick={() => handleChat()}
                                        className="text-sm whitespace-nowrap bg-primary rounded-xl text-white py-3 px-7 disabled:bg-gray-200 disabled:text-black"
                                    />
                                    : null
                                }
                            </div>

                            <div className='ml-auto space-y-4 max-w-40'>
                                <Button
                                    value={"View Details"}
                                    handleClick={() => handleViewDetails(id)}
                                    className={`${viewDetails[id] ? "bg-gray-50 text-black" : "bg-primary text-white"} text-sm whitespace-nowrap w-full border rounded-xl py-3 px-7 duration-200`}
                                />
                            </div>
                        </div>


                    </div>
                </div>

                {viewDetails[id] ?

                    <div className='pb-10'>
                        <div className='bg-white border rounded-lg divide-y' >

                            {/* Enquiry Details */}
                            <div className='p-6 flex justify-between items-center'>
                                <div className='text-xl font-semibold'>Enquiry Details</div>
                                <Button
                                    value={<IoMdClose className='text-3xl' />}
                                    handleClick={() => handleViewDetails(id)}
                                    className="text-black px-4"
                                />
                            </div>


                            {/* Lead */}
                            <div>
                                <div>
                                    {!isloading ?
                                        <div className='p-6 text-xl font-semibold w-fit '>{enquiryDetails?.[id]?.cat_name}</div>
                                        : null
                                    }
                                    <hr className='ml-4 bg-primary w-20 h-0.5' />
                                </div>

                                <div className='p-6 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                                    <div>
                                        <div className='text-sm text-gray-500'>Name</div>
                                        <div className='text-sm'>{leadData.name}</div>
                                    </div>
                                    <div>
                                        <div className='text-sm text-gray-500'>Location</div>
                                        <div className='text-sm'>{leadData.address}</div>
                                    </div>
                                    <div>
                                        <div className='text-sm text-gray-500'>When to start</div>
                                        <div className='text-sm'>{leadData.when_start}</div>
                                    </div>
                                    <div>
                                        <div className='text-sm text-gray-500'>State</div>
                                        <div className='text-sm'>{leadData.state}</div>
                                    </div>
                                    <div>
                                        <div className='text-sm text-gray-500'>Suburb</div>
                                        <div className='text-sm'>{leadData.suburb}</div>
                                    </div>
                                    {agents?.status ?
                                        <div>
                                            <div className='text-sm text-gray-500'>Status</div>
                                            <div className='text-sm'>{agents.status}</div>
                                        </div>
                                        :
                                        <div>
                                            <div className='text-sm text-gray-500'>Status</div>
                                            <div className='text-sm'>{enquiryDetailsData?.status}</div>
                                        </div>
                                    }


                                    {agents?.hired_at &&
                                        <div>
                                            <div className='text-sm text-gray-500'>Hired At</div>
                                            <div className='text-sm'>{formatDate(agents.hired_at)}</div>
                                        </div>
                                    }

                                    {agents?.closed_at &&
                                        <div>
                                            <div className='text-sm text-gray-500'>Closed At</div>
                                            <div className='text-sm'>{formatDate(agents.closed_at)}</div>
                                        </div>
                                    }

                                </div>

                                <div className='p-6 space-y-1'>
                                    <div className='text-sm text-gray-500'>Description</div>
                                    <div className='text-sm'>{leadData.description}</div>
                                </div>
                                {metaDesc &&
                                    <div className='p-6 space-y-1'>
                                        <Button
                                            value="View Description"
                                            className="bg-gray-200 text-black text-sm whitespace-nowrap border rounded-xl py-3 px-7 duration-200"
                                            handleClick={() => setViewMetaDesc(true)}
                                        />
                                    </div>
                                }
                            </div>

                            {/* Contact Details */}
                            <div>
                                <div>
                                    <div className='p-6 text-xl font-semibold w-fit '>Contact Details</div>
                                    <hr className='ml-6 bg-primary w-20 h-0.5' />
                                </div>

                                <div className='p-6 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                                    <div>
                                        <div className='text-sm text-gray-500'>Phone</div>
                                        <div className='text-sm'>{leadData.phone}</div>
                                    </div>
                                    <div>
                                        <div className='text-sm text-gray-500'>Post Code</div>
                                        <div className='text-sm'>{leadData.postcode}</div>
                                    </div>
                                    <div>
                                        <div className='text-sm text-gray-500'>Email</div>
                                        <div className='text-sm'>{leadData.email}</div>
                                    </div>
                                </div>

                                {/* {!isloading ?
                                    <div className='p-6 w-full flex-wrap md:flex-nowrap flex justify-between items-center gap-3'>
                                        <div className='text-sm space-y-2'>
                                            <div>Applied Companies: {totalCompany}</div>

                                        </div>
                                        {totalCompany ?
                                            < Button
                                                // disable={isStatus === "Closed"}
                                                value={"View Companies"}
                                                handleClick={() => handleChat()}
                                                className="text-sm bg-primary rounded-xl text-white py-3 px-7 disabled:bg-gray-200 disabled:text-black"
                                            />
                                            : null
                                        }
                                    </div>
                                    : null
                                } */}
                            </div>



                            {/* Images */}
                            {leadData?.images?.length ?
                                <div>
                                    <div>
                                        <div className='p-6 text-xl font-semibold w-fit '>Images</div>
                                        <hr className='ml-6 bg-primary w-20 h-0.5' />
                                    </div>

                                    <div className='p-6 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                                        {leadData.images.length &&
                                            leadData?.images.map((image, index) => (
                                                <div className='border rounded-2xl w-full aspect-square' key={index}>
                                                    <img src={image.image} className='p-2 w-full h-full object-contain' alt="" />
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                : <></>
                            }

                        </div>

                    </div>
                    : null
                }


            </div>

            <div>
                {viewMetaDesc &&

                    <Modal
                        title={"Description"}
                        contents={
                            <div
                                className='textsm'
                                dangerouslySetInnerHTML={{ __html: metaDesc }}
                            />
                        }
                        closeModal={() => setViewMetaDesc(false)}
                    />
                }

                {viewMetaDesc &&
                    <Overlay onClick={() => setViewMetaDesc(false)} />
                }
            </div>
        </>
    )
}

export default ListItems