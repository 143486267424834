import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchFindCompanyList, removeDirectoryData } from '../../Redux/FindCompany/action'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CompanyCard } from '../../Component'
import { Button } from 'react-scroll'
import { scrollToTop } from '../../Component/NavigateTop'
import { homeBg } from '../../Asset/Assest'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { saveGoogleLocation } from '../../Redux/Client/action'
import { fetchGetLeadForm } from '../../Redux/Enquiry/action'
import SimilarArticles from '../BlogDetails/SimilarArticles'
import { fetchGetAllNewsData, removeNewsData } from '../../Redux/News/action'

const FindCompany = ({ setToggle, setCatId }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(1)


    const id = searchParams.get('id')
    const state = searchParams.get('state')
    const postcode = searchParams.get('postcode')
    const suburb = searchParams.get('suburb')
    const catName = searchParams.get('cat')

    const blogs = useSelector((state) => state.news.news, shallowEqual);

    useEffect(() => {
        dispatch(fetchGetAllNewsData());
        return () => {
            dispatch(removeNewsData());
        };
    }, [dispatch]);


    const companyApiData = useSelector(state => state.findCompany.companyList, shallowEqual)
    const companyData = companyApiData?.agencies


    const params = `${state},${suburb},${postcode}`
    useEffect(() => {
        if (id) { dispatch(fetchFindCompanyList(id, params)) }

        return () => { removeDirectoryData() }
    }, [dispatch, id, params])


    const handlePrevData = () => {
        scrollToTop()
        setCurrentPage(currentPage - 1)
        dispatch(fetchFindCompanyList(id, params, currentPage - 1,));
    }

    const handleNextData = () => {
        scrollToTop()
        setCurrentPage(currentPage + 1)
        dispatch(fetchFindCompanyList(id, params, currentPage + 1,));
    }


    let showPage = false;
    if (companyData?.current_page === 1 && companyData?.has_next_page) { showPage = true; }
    else if (companyData?.current_page >= 1) { showPage = true; }
    else { showPage = false; }

    const handleGoogleLoc = (loc) => {
        dispatch(saveGoogleLocation(loc))
        sessionStorage.setItem("geoLoc", JSON.stringify(loc))

        handleGetQuoteForm()
    }

    const handleGetQuoteForm = () => {
        scrollToTop()
        setCatId(id)

        setToggle({
            start: false,
            category: false,
            field: true,
        });
        dispatch(fetchGetLeadForm(id));
        navigate("/")
    }

    return (
        <div className='bg-[#FAFAFA]'>

            <section className='relative w-full h-96 lg:h-[89vh]'>

                <div className='bg-[rgba(256,256,256,0.7)] rounded-md p-4 absolute z-10 whitespace-nowrap top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>

                    <div className='px-5 w-full flex flex-col justify-center items-center gap-6'>
                        <div className='w-full text-center text-xl'>
                            <div>Get multiple quotes from</div>
                            <div className='font-semibold'>{catName} in {suburb} {state}, </div>
                        </div>

                        <div className='flex flex-col justify-center items-center gap-3 w-full'>
                            <div className='w-[28rem]'>
                                <GooglePlacesAutocomplete
                                    placeholder="Enter Location"
                                    textInputProps={{
                                        placeholderTextColor: "#32a852",
                                        returnKeyType: "search",
                                    }}

                                    autocompletionRequest={{
                                        componentRestrictions: { country: ["au"] },
                                    }}
                                    selectProps={{
                                        onChange: handleGoogleLoc,
                                        placeholder: "Wanna select different location?",
                                        styles: {
                                            input: (provided) => ({
                                                ...provided,
                                                padding: "0.625rem 0.5rem",
                                            }),
                                        },
                                    }}
                                />
                            </div>

                            <div className='pr-8 h-full w-full lg:w-fit flex justify-center items-center'>
                                <button
                                    onClick={handleGetQuoteForm}
                                    className='whitespace-nowrap bg-primary text-white text-sm md:text-base px-4 py-2 md:px-8 md:py-3 rounded-md'
                                >Get a Quote
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <img src={homeBg} className='w-full h-full object-cover' alt="" />
            </section>

            <section className='px-2 md:px-16 lg:px-20 xl:px-28 2xl:px-80 mx-auto py-10 md:py-16 flex flex-col lg:flex-row gap-6 lg:gap-x-8'>

                <div className='space-y-10 h-fit w-full lg:w-[67.66%]'>

                    <div className='grid gap-4 md:gap-6 grid-cols-1'>
                        {companyData?.length ? companyData.map((val) => (
                            <CompanyCard
                                key={val.id}
                                userId={val.user_id}
                                companyName={val.company_name}
                                streetAddress={val.street_address}
                                state={val.state}
                                suburb={val.suburb}
                                bio={val.bio}
                                image={val.banner_image}
                                rating={val.rating}
                                handleGetQuoteForm={handleGetQuoteForm}
                            />

                        )) :
                            <div className='p-6 duration-300 gap-4 flex rounded-md bg-white shadow-[1px_2px_5px_rgba(0,0,0,0.1)]'>
                                No Company Found
                            </div>
                        }
                    </div>

                    {showPage ?
                        <>
                            <div className="w-full p-3 border bg-white rounded-lg justify-between flex items-center">
                                {companyData?.current_page > 1 &&
                                    <div>
                                        <Button
                                            value="Prev"
                                            className="bg-gray-50 rounded-xl border py-2 px-5"
                                            handleClick={handlePrevData}
                                        />
                                    </div>
                                }
                                {companyData?.has_next_page &&
                                    <div className="ml-auto">
                                        <Button
                                            value="Next"
                                            className="bg-gray-50 rounded-xl border py-2 px-5"
                                            handleClick={handleNextData}
                                        />
                                    </div>
                                }
                            </div>
                        </>
                        : null
                    }
                </div>

                <div className='w-[30%]'>
                    <div className='flex flex-col gap-4 items-center justify-start rounded-md p-6 bg-white shadow-[1px_2px_5px_rgba(0,0,0,0.1)]'>
                        <div className='text-primary'>Why choose us?</div>

                        <div className='space-y-2 text-gray-400 text-sm'>
                            <div>Get multiple quotes</div>
                            <div>Choose the business that suits you</div>
                            <div>3 step verification process</div>
                            <div>Free to use with no obligation to hire</div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='px-2 md:px-16 lg:px-20 xl:px-28 2xl:px-80 mx-auto py-10'>
                <SimilarArticles blogs={blogs} />
            </div>
        </div>
    )
}

export default FindCompany