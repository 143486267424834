import React from 'react'
import { formatDate } from "../../utils/formatDate"
import { IoIosStar } from "react-icons/io";
import { getStarsFromRating } from '../../utils/getStarsFromRating';
import Loading from '../../Component/Loading/Loading';


const Rating = ({ leadReviews }) => {

    if (!leadReviews) {
        return (
            <div className="w-full h-screen">
                <Loading />
            </div>
        );
    }

    return (
        <div className='py-6 '>
            <div className='px-6'>
                <div className='gap-3 flex items-center justify-start'>

                    <div className='w-12 aspect-square rounded-full shadow-inner bg-gray-200'>
                        <img src={leadReviews.image} className='rounded-full w-full h-full object-contain object-center' alt="" />
                    </div>
                    <div>
                        <div className='text-base'>{leadReviews.first_name} {leadReviews.last_name}</div>
                        <div className='text-xs text-[#474D59]'>Rewiewed on {formatDate(leadReviews.added_at)}</div>
                    </div>
                </div>

                <div className='mt-3 flex justify-start items-center gap-2 flex-wrap'>
                    <div className='flex items-center justify-start flex-wrap gap-x-0.5 text-xl'>
                        {getStarsFromRating(Math.min(leadReviews.rate), <IoIosStar className='text-[#D4CDC5]' />)}
                    </div>
                    {/* <div className='text-base font-medium whitespace-nowrap'>Definitely worth the price</div> */}
                </div>

                {/* comments */}
                <div className='text-sm text-[#474D59] mt-3 text-left'>
                    {leadReviews.message}
                </div>


            </div>
        </div>)
}

export default Rating