import React from 'react'
import { enquiryImg } from '../../Asset/Assest';
import { useNavigate } from "react-router-dom";
import { ItemsCarousel, NewsCard } from '../../Component';
import { scrollToTop } from '../../Component/NavigateTop';


const SimilarArticles = ({ blogs }) => {

    const displayArr = blogs?.blogs?.slice(0, 6);

    // const testArr = [
    //     {
    //         id: 1,
    //         image: enquiryImg,
    //         title: "ClassiLead",
    //         desc: "This article is about description and cats and dogs and cats and dogs"
    //     },
    //     {
    //         id: 2,
    //         image: enquiryImg,
    //         title: "ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead",
    //         desc: "This article "
    //     },
    //     {
    //         id: 3,
    //         image: enquiryImg,
    //         title: "ClassiLead ",
    //         desc: "This article "
    //     },
    //     {
    //         id: 4,
    //         image: enquiryImg,
    //         title: "ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead",
    //         desc: "This article is about description and cats and dogs and cats and dogs"
    //     },
    //     {
    //         id: 5,
    //         image: enquiryImg,
    //         title: "ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead ClassiLead",
    //         desc: "This article is about description and cats and dogs and cats and dogs"
    //     }
    // ]


    const navigate = useNavigate()

    const executeFunctions = (id) => {
        scrollToTop();
        navigate(`/blog/${id}`);
    };
    return (
        <div className='space-y-8'>
            <div>
                <div className='text-xl md:text-3xl font-semibold'>Read Similar Articles</div>
                <hr className='bg-primary h-1 w-20' />
            </div>

            <div>
                <ItemsCarousel
                    className={"px-3 py-6"}
                    slidesToShow={displayArr?.length > 4 ? 4 : displayArr?.length}
                    contents={displayArr?.map((val) => (
                        <NewsCard
                            key={val.id}
                            id={val.id}
                            image={val.picture}
                            title={val.title}
                            desc={val.content}
                            date={val.added_date}
                            executeFunctions={executeFunctions}
                        />
                    ))}
                />
            </div>
        </div>
    )
}

export default SimilarArticles