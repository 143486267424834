import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from "js-cookie";
import { getCompanyProfile, postCompanyReview, removeCompanyData } from '../../Redux/CompanyDetail/action';
import Loading from '../../Component/Loading/Loading';
import { GoArrowRight } from "react-icons/go";
import { Button, FormikControl } from '../../Component';
import { IoIosArrowDown } from 'react-icons/io';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import WriteAReview from '../Chat/WriteAReview';
import Rating from '../Chat/Rating';



const choices = {
    companyInfo: "Company Info",
    viewReview: "View reviews",
    writeReview: "Write a review",
}

const CompanyDetailPage = () => {

    const { id } = useParams()

    const [starRating, setStarRating] = useState(null)
    const [activeFilter, setActiveFilter] = useState("Company Info")

    const dispatch = useDispatch()
    const token = Cookies.get("token");

    useEffect(() => {
        dispatch(getCompanyProfile(id, token))
        return () => {
            dispatch(removeCompanyData())
        }
    }, [dispatch, token, id])

    const loading = useSelector((state) => state.companyReducer.isloading)




    const handleSendReview = useCallback((values, onSubmitProps) => {
        if (!starRating) {
            toast.info("Please select a star rating")
            return
        }

        const body = {
            "agency_id": id,
            "message": values.message,
            "rate": starRating,
            "lead_request_id": null
        };

        dispatch(postCompanyReview(body, token));
        setTimeout(() => {
            dispatch(getCompanyProfile(id, token));
            onSubmitProps.setSubmitting(false);
            onSubmitProps.resetForm();
        }, 500);
        setTimeout(() => {
        }, 1000);
        toast.info("Sending... Please wait.")

    }, [dispatch, id, starRating, token]);




    const handleChoices = (choice) => {
        setActiveFilter(choice)
    }

    const [reviewed, setReviewed] = useState(false)

    const displayData = useMemo(() => {

        const { companyInfo, viewReview, writeReview } = choices

        let content;

        if (activeFilter === companyInfo) { content = <ContentCompany /> }
        else if (activeFilter === viewReview) { content = <ContentReviews /> }
        else if (activeFilter === writeReview) {
            content =
                <ContentWriteReview
                    starRating={starRating}
                    setStarRating={setStarRating}
                    handleSendReview={handleSendReview}
                    reviewed={reviewed}
                    setReviewed={setReviewed}
                />
        }
        else content = <></>

        return content;

    }, [activeFilter, handleSendReview, starRating, reviewed])


    if (loading) {
        return (
            <div className="w-full h-screen">
                <Loading />
            </div>
        );
    }


    return (
        <div className='bg-[#FAFAFA] py-10 md:py-16 px-2 md:px-16 lg:px-20 xl:px-28 2xl:px-80 mx-auto'>
            <div className='bg-white rounded-lg divide-y' >

                <div className="w-full overflow-y-auto mx-0 px-0">
                    <div className="flex mr-auto justify-start divide-x items-center">
                        {Object.entries(choices).map(([key, value]) => (
                            <Button
                                key={key}
                                value={value}
                                handleClick={() => handleChoices(value)}
                                className={`${activeFilter === value ? "border-b-primary text-black" : "border-gray-200 text-[#70747D]"} 
                            w-full whitespace-nowrap border-b-2 py-2 px-4 lg:px-6 lg:py-3 text-sm lg:text-base`}
                            />
                        ))}
                    </div>
                </div>

                {displayData}

            </div>
        </div>
    )
}

export default CompanyDetailPage


function ContentCompany() {

    const compProfile = useSelector((state) => state.companyReducer.company)

    return (
        <>
            <div>
                <div className='p-6 pb-0 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                    <div>
                        <div className='text-sm text-gray-500'>Name</div>
                        <div className='text-sm'>{compProfile?.company_name}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>Location</div>
                        <div className='text-sm'>{compProfile.location}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>street_address</div>
                        <div className='text-sm'>{compProfile.street_address}</div>
                    </div>

                    <div>
                        <div className='text-sm text-gray-500'>State</div>
                        <div className='text-sm'>{compProfile?.users?.state}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>Total credits</div>
                        <div className='text-sm'>{compProfile.users.total_credits}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>Suburb</div>
                        <div className='text-sm'>{compProfile?.users.suburb}</div>
                    </div>
                </div>

                <div className='p-6'>
                    <div className='text-sm text-gray-500'>Credit Subscription Expired Date</div>
                    <div className='text-sm'>{compProfile?.users.enquiry_credit_subscription_expired_date}</div>
                </div>
                <div className='p-6 pt-0'>
                    <div className='text-sm text-gray-500'>Bio</div>
                    <div className='text-sm'>{compProfile?.bio}</div>
                </div>
            </div>

            {/* Contact Details */}
            <div>
                <div>
                    <div className='p-6 text-xl font-semibold w-fit '>Contact Details</div>
                    <hr className='ml-6 bg-primary w-20 h-0.5' />
                </div>

                <div className='p-6 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                    <div>
                        <div className='text-sm text-gray-500'>Phone</div>
                        <div className='text-sm'>{compProfile.contact_number}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>Email</div>
                        <div className='text-sm'>{compProfile.user_email}</div>
                    </div>
                </div>
            </div>

            {/* Images */}
            {compProfile?.lead_profile?.images?.length ?
                <div>
                    <div>
                        <div className='p-6 text-xl font-semibold w-fit '>Images</div>
                        <hr className='ml-6 bg-primary w-20 h-0.5' />
                    </div>

                    <div className='p-6 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(50px,1fr))]'>
                        {compProfile?.lead_profile?.images.map((image, index) => (
                            <div className='border rounded-2xl w-full aspect-square' key={index}>
                                <img src={image.image} className='p-2 w-full h-full object-contain' alt="" />
                            </div>
                        ))
                        }

                    </div>
                </div>
                : <></>
            }
        </>
    )
}


function ContentReviews() {

    const compProfile = useSelector((state) => state.companyReducer.company);

    const displayData = compProfile?.lead_reviews;

    if (displayData) {
        displayData.sort((a, b) => {
            return new Date(b.added_at) - new Date(a.added_at);
        });
    } else {
        console.log("compProfile or lead_reviews is undefined");
    }


    const [viewLimit, setViewLimit] = useState(20);
    const showMore = displayData.length > 20 ? true : false;
    const [showViewMore, setShowViewMore] = useState(showMore);

    const handleViewMore = () => {
        setViewLimit((prev) => {
            if (prev + 20 >= displayData.length) {
                setShowViewMore(false)
                return displayData.length
            }
            return (prev + 20)
        })
    }
    return (
        <>
            {compProfile?.lead_reviews?.length ?
                <div>
                    <div>
                        <div className='p-6 text-xl font-semibold w-fit '>Reviews</div>
                        <hr className='ml-6 bg-primary w-20 h-0.5' />
                    </div>

                    {/* {compProfile?.lead_reviews.map((data) => ( */}
                    {displayData.slice(0, viewLimit).map((data, index) => (
                        <Rating key={index} leadReviews={data} />
                    ))}

                    {showViewMore &&
                        <div className='mb-5 w-full rounded-md shadow-[2px_2px_5px_rgba(0,0,0,0.5)] bg-gradient-to-t from-customblue from-5% via-blue-500 to-95% to-customblue flex justify-center items-center'>
                            <button
                                onClick={() => handleViewMore()}
                                className="w-full text-white text-sm flex items-center justify-center gap-x-1"
                            >
                                <div>View more</div>
                                <IoIosArrowDown className='w-8 h-8 px-2' />
                            </button>
                        </div>
                    }

                </div>
                :
                <>
                    <div className='min-h-[70vh] lg:min-h-[80vh] w-full flex items-center justify-center'>

                        <div className='w-full mx-auto gap-y-8 p-4 flex flex-col items-center justify-center'>

                            <h2 className='text-center text-lg md:text-3xl font-semibold'>No Reviews</h2>

                        </div>
                    </div>
                </>
            }


        </>
    )
}


function ContentWriteReview({
    starRating,
    setStarRating,
    handleSendReview,
    reviewed,
    setReviewed
}) {

    const initialValues = {
        message: "",
    };

    const validationSchema = Yup.object().shape({
        message: Yup.string().required("Required*"),
    });

    const success = useSelector((state) => state.companyReducer.success)
    const review = useSelector((state) => state.companyReducer.review)

    useEffect(() => {
        if (!reviewed && success && review) {
            toast.success(review)
            setReviewed(true)
        }
    }, [review, success, reviewed, setReviewed])


    return (
        <>
            {success && review ?

                <>
                    <div className='min-h-[70vh] lg:min-h-[80vh] w-full flex items-center justify-center'>

                        <div className='w-full mx-auto gap-y-8 p-4 flex flex-col items-center justify-center'>


                            <h2 className='text-center text-lg md:text-3xl font-semibold'>Thank&nbsp;your for&nbsp;your&nbsp;review!</h2>


                        </div>
                    </div>
                </>

                :

                <div>
                    <div>
                        <div className='p-6 text-xl font-semibold w-fit '>Rate this company</div>
                        <hr className='ml-6 bg-primary w-20 h-0.5' />
                    </div>

                    {/* Write a Review */}
                    <div className='px-2 md:px-6 py-6 text-center space-y-4'>
                        <div className='whitespace-nowrap font-semibold text-lg'>How was your experience with us?</div>

                        <WriteAReview
                            rating={starRating}
                            setRating={setStarRating}
                        />
                    </div>

                    <div className='px-2 md:px-6 py-6 text-center space-y-4'>
                        <div className='lg:whitespace-nowrap font-semibold text-lg'>Feel free to share about your experience with us</div>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSendReview}
                            validationSchema={validationSchema}
                            validateOnMount
                        >
                            {() => (
                                <Form>

                                    <FormikControl
                                        label="Message"
                                        control="textarea"
                                        name="message"
                                        placeholder="Tell us about your query"
                                    />

                                    <div className='px-6 pb-6 w-full flex justify-end items-center'>
                                        <button
                                            type='submit'
                                            className={"py-3 px-6 text-base border whitespace-nowrap border-secondary bg-primary text-white rounded-md flex justify-center items-center gap-2 disabled:bg-gray-200 disabled:text-black"}
                                        >
                                            <div>Submit your Review</div>
                                            <GoArrowRight />
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            }
        </>
    )
}