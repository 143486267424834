import React from 'react'
import { BsArrowRight } from "react-icons/bs";
import { defImg } from '../../Asset/Assest';
import { formatDate } from '../../utils/formatDate'


const BlogCard = ({
    className,
    title,
    image,
    date,
    desc,
    onClick
}) => {


    return (
        <button onClick={onClick} className={`${className} hover:cursor-pointer bg-white p-4 rounded-md group flex flex-col items-center justify-start gap-y-2 xl:gap-y-5 hover:shadow-[1px_2px_10px_rgba(0,0,0,0.2)] shadow-[1px_2px_5px_rgba(0,0,0,0.1)]`}>

            <div className='w-full aspect-video rounded-md overflow-hidden'>
                <img
                    className='group-hover:scale-110 duration-300 ease-in-out object-cover object-center w-full h-full'
                    src={image ?? defImg}
                    onError={(e) => e.target.src = defImg}
                    alt={'items'}
                />
            </div>
            <div className='w-full flex flex-col items-start flex-grow justify-between gap-2'>
                <div className='text-[#70747D] text-sm'>{formatDate(date)}</div>
                <div className='text-black group-hover:text-secondary line-clamp-2 text-base lg:text-sm xl:text-base font-medium'>{title}</div>
                <div
                    dangerouslySetInnerHTML={{ __html: desc }}
                    className='text-[#70747D] line-clamp-2 text-sm text-start lg:text-xs xl:text-sm'
                />

                <div
                    className={'mt-2 rounded-md bg-white group-hover:bg-primary group-hover:text-white text-secondary border border-secondary flex justify-center items-center gap-2 py-3 px-5 lg:px-4 lg:py-2 xl:py-3 xl:px-5 duration-300'}
                >
                    <p className='whitespace-nowrap text-sm xl:text-base'>Read More</p>
                    <BsArrowRight className='text-sm lg:text-base xl:text-xl' />
                </div>
            </div>

        </button>
    )
}

export default BlogCard