
const initialState = {
    gogLoc: null,
}

export const googleLocationReducer = (state = initialState, action) => {
    switch (action.type) {

        case "SAVE_GOOGLE_LOC":
            return {
                ...state,
                gogLoc: action.payload
            }

        case "REMOVE_GOOGLE_LOC":
            return {
                ...state,
                gogLoc: null
            }

        default:
            return state
    }
}
