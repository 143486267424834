export const PROFILE_LOADING = "PROFILE_LOADING";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const FAIL_GET_USER_PROFILE = "FAIL_GET_USER_PROFILE";
export const REMOVE_PROILES_DATA = "REMOVE_PROFILES_DATA";

export const FAIL_DEACTIVATE_REASON = "FAIL_DEACTIVATE_REASON";
export const GET_DEACTIVATE_REASON = "GET_DEACTIVATE_REASON";

export const POST_DEACTIVATE_FAIL = "POST_DEACTIVATE_FAIL";
export const POST_DEACTIVATE_SUCCESS = "POST_DEACTIVATE_SUCCESS";
