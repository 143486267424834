import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchGetDirectorySuggestions } from '../../Redux/Directory/action';
import Loading from '../../Component/Loading/Loading';
import useDebounce from '../../hooks/useDebounce';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { getLocationData } from '../../utils/location';
import { saveGoogleLocation } from '../../Redux/Client/action';
import { removeDirectoryData } from '../../Redux/FindCompany/action';

const SearchField = ({ handleClickNext }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [keyword, setKeyword] = useState("");
    const [location, setLocation] = useState(null)
    const [showSuggestions, setShowSuggestions] = useState(true)
    const [catId, setCatId] = useState(null)

    const suggestions = useSelector((state) => state.directory.suggestions, shallowEqual);
    const loading = useSelector((state) => state.directory.isloading, shallowEqual);
    const debouncedKeyword = useDebounce(keyword, 300);

    useEffect(() => {
        dispatch(fetchGetDirectorySuggestions(debouncedKeyword));
        return () => { removeDirectoryData() }
    }, [debouncedKeyword, dispatch]);

    const handelSearch = () => {
        if (!catId) return

        if (!location) {
            handleClickNext(catId)
        } else {
            const queryParams = new URLSearchParams();
            queryParams.set('id', catId);
            queryParams.set('cat', keyword);
            queryParams.set('state', location.state);
            queryParams.set('postcode', location.postcode);
            queryParams.set('suburb', location.suburb);

            navigate(`/find-company?${queryParams}`)
        }
    }

    const handleGoogleLoc = async (loc) => {
        const { state, postcode, suburb } = await getLocationData(loc)
        setLocation({ state, postcode, suburb })
        dispatch(saveGoogleLocation(loc))
        sessionStorage.setItem("geoLoc", JSON.stringify(loc))
    }

    return (
        <div className="w-full bg-primary p-5 mt-10">
            <div className="w-11/12 xl:w-3/4 mx-auto">
                <h6 className="text-xl text-white font-medium">
                    What are you looking for?
                </h6>

                <div className="mt-10 w-full">

                    <div className='w-full flex gap-6 flex-wrap md:flex-nowrap justify-between items-stretch'>
                        <div className="relative w-full bg-white border rounded py-2.5 px-2 gap-x-2 flex items-center">
                            <input
                                type="text"
                                className="py-1 w-full outline-none px-3"
                                placeholder="I'm looking for..."
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                onFocus={() => setShowSuggestions(true)}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setShowSuggestions(false)
                                    }, 500);
                                }}
                            />

                            {showSuggestions && keyword ?
                                <div className="max-h-52 rounded-md overflow-y-auto -translate-x-2 absolute z-10 bg-white top-full w-full border">
                                    {loading ? (
                                        <div className="w-full h-full min-h-52 mt-5">
                                            <Loading />
                                        </div>
                                    ) : (
                                        <div>

                                            {suggestions.length ? suggestions?.map((val, index) => (

                                                <div
                                                    key={index}
                                                    className="py-2 px-4 border-b cursor-pointer hover:bg-gray-50"
                                                    // onClick={() => { console.log(val.cat_id) }}
                                                    onClick={() => {
                                                        setKeyword(val.cat_name);
                                                        setShowSuggestions(false);
                                                        setCatId(val.cat_id)
                                                    }}
                                                >
                                                    {val.cat_name}
                                                </div>
                                            ))
                                                :
                                                <div className="py-4 px-4 text-xs text-gray-400 border-b cursor-pointer hover:bg-gray-50">
                                                    No Results Found
                                                </div>
                                            }
                                        </div>
                                    )}

                                </div>
                                :
                                <></>
                            }
                        </div>


                        <div className="w-full">

                            <GooglePlacesAutocomplete
                                placeholder="Enter Location"
                                textInputProps={{
                                    placeholderTextColor: "#32a852",
                                    returnKeyType: "search",
                                }}

                                autocompletionRequest={{
                                    componentRestrictions: { country: ["au"] },
                                }}
                                selectProps={{
                                    onChange: handleGoogleLoc,
                                    placeholder: "Enter your Preferred Location",
                                    styles: {
                                        input: (provided) => ({
                                            ...provided,
                                            padding: "0.625rem 0.5rem",
                                        }),
                                    },
                                }}
                            />
                        </div>

                        <div className='flex-grow'>
                            <button
                                disabled={!catId}
                                onClick={handelSearch}
                                className='h-full bg-white px-6 py-2 rounded-md text-black disabled:bg-slate-500 disabled:text-gray-200'
                            >Search</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>)
}

export default SearchField