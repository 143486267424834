import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { Auth } from "./Auth/reducer";
import { Profile } from "./Profile/reducer";
import { Enquiry } from "./Enquiry/reducer";
import { Profiles } from "./UserProfile/reducer";
import { Notification } from "./Notification/reducer";
import { TermsPrivacy } from "./Terms-Privacy/reducer";
import { Category } from "./Category/reducer";
import { Image } from "./Images/reducer";
import { Directory } from "./Directory/reducer";
import { Inbox } from "./Inbox/reducer"
import { News } from "./News/reducer";
import { Contact } from './ContactUs/reducer'
import { companyReducer } from './CompanyDetail/reducer'
import { FindCompany } from "./FindCompany/reducer";
import { googleLocationReducer } from "./Client/reducer";


export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      auth: Auth,
      profile: Profile,
      profiles: Profiles,
      enquiry: Enquiry,
      notification: Notification,
      termsprivacy: TermsPrivacy,
      category: Category,
      image: Image,
      directory: Directory,
      inbox: Inbox,
      news: News,
      contact: Contact,
      companyReducer: companyReducer,
      findCompany: FindCompany,
      googleLoc: googleLocationReducer
    }),

    process.env.NODE_ENV === "development"
      // ? compose(composeWithDevTools(applyMiddleware(...[thunk, logger])))
      ? compose(composeWithDevTools(applyMiddleware(...[thunk])))
      : compose(applyMiddleware(...[thunk]))
  );

  return store;
};
