import React from 'react'
import { defImg } from '../../Asset/Assest';
import { formatDate } from '../../utils/formatDate';

// Cards for Carousel
function NewsCard(props) {

    const {
        id,
        image,
        title,
        desc,
        date,
        executeFunctions,

        // props from carousel
        handleMouseDown,
        handleMouseMove,
        setIsMouseDown,
        condition = true
    } = props

    const handleMouseUp = () => {
        setIsMouseDown && setIsMouseDown(false);

        if (condition) {
            executeFunctions(id)
        }
    };


    return (


        <div
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            className={` hover:cursor-pointer bg-white p-4 rounded-md group flex flex-col items-center justify-start gap-y-2 xl:gap-y-5 hover:shadow-[1px_2px_10px_rgba(0,0,0,0.2)] shadow-[1px_2px_5px_rgba(0,0,0,0.1)]`}
        >

            <div className='w-full aspect-video rounded-md overflow-hidden'>
                <img
                    className='group-hover:scale-110 duration-300 ease-in-out object-cover object-center w-full h-full'
                    src={image ?? defImg}
                    onError={(e) => e.target.src = defImg}
                    alt={'items'}
                />
            </div>
            <div className='w-full flex flex-col items-start flex-grow justify-between gap-2'>
                <div className='text-[#70747D] text-sm'>{formatDate(date)}</div>
                <div className='text-black group-hover:text-secondary line-clamp-2 text-base lg:text-sm xl:text-base font-medium'>
                    {title} <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                </div>
                <div
                    dangerouslySetInnerHTML={{ __html: desc }}
                    className='text-[#70747D] line-clamp-2 text-sm text-start lg:text-xs xl:text-sm'
                />
            </div>

        </div >

    )
}

export default NewsCard


// <div
// // To prevent unintended click behavior when selecting and swiping cards on the production side. This ensures the onClick function is only triggered on intended clicks, not during swipe gestures.
// onMouseDown={handleMouseDown}
// onMouseUp={handleMouseUp}
// onMouseMove={handleMouseMove}
// className={`w-full h-full group duration-300 md:min-h-[18.25rem] border overflow-hidden rounded-lg flex flex-col justify-stretch items-center cursor-pointer gap-y-4`}
// >

// <div className="w-full overflow-hidden">
//     <div className="max-w-80 w-full min-h-40 aspect-[13/9]">
//         {/* {console.log(image)} */}
//         <img
//             src={image ?? defImg}
//             onError={(e) => e.target.src = defImg}
//             className=" group-hover:scale-125 duration-200 w-full h-full object-cover"
//             alt={""}
//         />
//     </div>
// </div>


// <div className="w-full max-w-80 flex-grow flex flex-col justify-between items-start p-2 gap-3">
//     <div className="line-clamp-2 text-primary text-sm">{title}</div>


//     {desc &&
//         <div
//             className="text-textSecondary line-clamp-2"
//             dangerouslySetInnerHTML={{ __html: desc }}
//         />
//         // <div className="line-clamp-2">{desc}</div>
//     }
// </div>
// </div>