import React, { useState } from 'react';
import { IoIosStar } from 'react-icons/io';

const WriteAReview = ({ rating, setRating }) => {


    const [hoveredRating, setHoveredRating] = useState(0);

    const handleStarClick = (selectedRating) => {
        setRating(selectedRating);
    };

    const handleStarHover = (hoveredRating) => {
        setHoveredRating(hoveredRating);
    };

    const resetHover = () => {
        setHoveredRating(0);
    };

    const getRatingText = () => {
        const roundedRating = Math.round(hoveredRating || rating);

        switch (roundedRating) {
            case 1:
                return { text: "Bad", color: "red-500" };
            case 2:
                return { text: "Poor", color: "red-500" };
            case 3:
                return { text: "Ok", color: "black" };
            case 4:
                return { text: "Good", color: "black" };
            case 5:
                return { text: "Excellent", color: "black" };
            default:
                return { text: "Select a star rating", color: "black" };
        }
    };

    return (
        <div>
            <p className={`text-center mt-2 text-${getRatingText().color}`}>
                {getRatingText().text}
            </p>
            <div className='flex items-center justify-center flex-wrap gap-x-0.5 text-xl'>
                {[1, 2, 3, 4, 5].map((index) => (
                    <IoIosStar
                        key={index}
                        className={`text-[#ECEDEE] text-5xl cursor-pointer transition ${index <= (hoveredRating || rating) ? 'text-yellow-500' : ''}`}
                        onMouseEnter={() => handleStarHover(index)}
                        onMouseLeave={resetHover}
                        onClick={() => handleStarClick(index)}
                    />
                ))}
            </div>
        </div>
    );
};


export default WriteAReview
